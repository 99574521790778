function Hospedagem() {
  const destinos = [
    { title: 'Hotel', description: '', backgroundImage: 'hotel.jpg', text: 'A partir de R$ 499,00', link: 'destino-hotel' },
    { title: 'Pousada', description: '', backgroundImage: 'pousada.jpeg', text: 'A partir de R$ 499,00', link: 'destino-pousada' },
    { title: 'Chalé', description: '', backgroundImage: 'chale.jpeg', text: 'A partir de R$ 499,00', link: 'destino-chale' },
    { title: 'Camping', description: '', backgroundImage: 'camping.jpg', text: 'A partir de R$ 499,00', link: 'destino-camping' },
    { title: 'Hostels', description: '', backgroundImage: 'hostel.jpg', text: 'A partir de R$ 499,00', link: 'destino-hostels' },
  ];

  return (
    <div>
      <h2>Desfrute do melhor dos dois mundos: Conforto e Estilo.</h2>
      <p>Em nossa busca por parcerias excepcionais, estamos comprometidos em oferecer a você uma experiência única de hospedagem. Entendemos que, ao viajar, conforto e estilo não são opostos, mas sim complementares. É por isso que selecionamos cuidadosamente opções de hospedagem que proporcionam o equilíbrio perfeito entre aconchego e elegância.</p>
      <div className="card-container">
        {destinos.map((destino, index) => (
          <a key={index} href={destino.link} className="card" style={{ backgroundImage: `url(${destino.backgroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center', width: '23%', position: 'relative' }}>
            <h3 style={{ position: 'absolute', top: '10px', left: '10px', color: '#fff', maxWidth: '90%', textAlign: 'left' }}>{destino.title}</h3>
            <p className="card-text">{destino.text}</p>
          </a>
        ))}
      </div>
    </div>
  );
}
export default Hospedagem;
