import './Contato.css';

import React, { Component } from 'react';

function Contato() {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Adicione sua lógica de envio de formulário aqui
    // Por enquanto, apenas exibiremos uma mensagem de sucesso no console
    console.log('Formulário enviado com sucesso!');
  }

  return (
    <div>
      <h2>Fale conosco</h2>

      <form onSubmit={handleSubmit}>
        <div className="Form">
          <label htmlFor="nome">Nome:</label>
          <input type="text" id="nome" name="nome" required />
        </div>
        <div>
          <label htmlFor="email">E-mail:</label>
          <input type="email" id="email" name="email" required />
        </div>
        <div>
          <label htmlFor="mensagem">Mensagem:</label>
          <textarea id="mensagem" name="mensagem" required />

          <p> &#x260E;  (11) 5050-2769 </p>
          <p> Segunda à Sexta: 09h às 18h</p>
        </div>
          <div class="button-container">

        <button type="submit">Enviar</button>
        </div>
      </form>
    </div>
  );
}

export default Contato;
