import React from 'react';
import './Footer.css';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';

function Footer() {
  return (

    <footer>
      <div id="redes-sociais">
      <a href="#" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
      <a href="#" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
      <a href="#" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
      <a href="#" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
      <a href="#" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
    </div>
      <div className="footer-content">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>

        <div style={{ flex: 1 }}>
          <h4>Seguro Viagem</h4>
        </div>
          <div style={{ flex: 1 }}>
          <h4>Passeios e Excursões</h4>
        </div>
          <div style={{ flex: 1 }}>
          <h4>Viagens Corporativas</h4>
        </div>
        </div>
              <p> &copy; 2024 PASSA BRASIL  | <a href="http://www.vedo.com.br" target="_blank" rel="noopener">Vedo</a>| Todos os direitos reservados. </p>
      </div>
    </footer>
  );
}




















export default Footer;
