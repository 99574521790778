import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Carrossel.css';

function Carrossel() {
  const imageStyles = {
    maxHeight: '400px',
  };

  return (
    <div className="carousel-container"> {/* Adicione a classe ao contêiner */}
      <Carousel showStatus={false} showThumbs={false} infiniteLoop autoPlay>
        <div>
          <a href="/Pacotes">
            <img src="diamundialdoturismo.jpg" alt="Imagem 1" style={imageStyles} />
            <p className="legend">Confira</p>
          </a>
        </div>
        <div>
          <a href="/carros">
            <img src="diamundialdoturismo.jpg" alt="Imagem 2" style={imageStyles} />
            <p className="legend">Confira</p>
          </a>
        </div>
        <div>
          <a href="https://shope.ee/3AgNX014oy">
            <img src="shopee.png" alt="Imagem 3" style={imageStyles} />
            <p className="legend">Confira</p>
          </a>
        </div>
      </Carousel>
    </div>
  );
}

export default Carrossel;
