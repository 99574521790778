import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './Header';
import Footer from './Footer';
import PassagensAereas from './Passagem';
import Pacotes from './Pacotes';
import Hospedagem from './Hospedagem';
import Carros from './Carros';
import Passeios from './Passeios';
import Contato from './Contato';
import Carrossel from './Carrossel';
import Newsletter from './Newsletter';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use

const firebaseConfig = {
  apiKey: "AIzaSyB9oClV6EUsYfQatVy1PSztCKjMbE2pBFQ",
  authDomain: "passabrasil-bea91.firebaseapp.com",
  projectId: "passabrasil-bea91",
  storageBucket: "passabrasil-bea91.appspot.com",
  messagingSenderId: "428658173646",
  appId: "1:428658173646:web:b0e0f256ac34006ef9529f",
  measurementId: "G-7NPRT1TE5Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


function Home() {
  return (
    <div>
    <Carrossel/>
    <Pacotes/>
    <passeios/>
    </div>
  );
}


function App() {
  return (
    <Router>
      <div>
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/passagens-aereas" element={<PassagensAereas />} />
            <Route path="/pacotes" element={<Pacotes />} />
            <Route path="/hospedagem" element={<Hospedagem />} />
            <Route path="/carros" element={<Carros />} />
            <Route path="/passeios" element={<Passeios />} />
            <Route path="/contato" element={<Contato />} />
          </Routes>
        </main>
        <Newsletter />
        <Footer />
      </div>
    </Router>
  );
}
export default App;