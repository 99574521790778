function Pacotes() {

  const destinos = [
    { title: 'Fortaleza', description: 'Descrição do Fortaleza', backgroundImage: 'Fortaleza.jpg', text: '' },
    { title: 'Salvador', description: 'Descrição de Salvador', backgroundImage: 'Salvador.jpeg', text: '' },
    { title: 'Recife', description: 'Descrição de Recife', backgroundImage: 'Recife.jpg', text: '' },
    { title: 'Rio de Janeiro', description: 'Descrição do Rio', backgroundImage: 'Rio.jpeg', text: '' },
  ];

  return (
    <div>
      <h2>Escolha o seu próximo sdestino:</h2>
      <div className="card-container">
        {destinos.map((destino, index) => (
          <div key={index} className="card" style={{ backgroundImage: `url(${destino.backgroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center', width: '23%', position: 'relative' }}>
            <h3 style={{ position: 'absolute', top: '10px', left: '10px', color: '#fff', maxWidth: '90%', textAlign: 'left' }}>{destino.title}</h3>
            <p className="card-text">{destino.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
export default Pacotes;