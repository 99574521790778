function Carros() {
  const cidades = [
    "Rio de Janeiro",
    "São Paulo",
    "Salvador",
    "Fortaleza",
    "Recife",
    "Belo Horizonte",
    "Foz do Iguaçu",
    "Manaus",
    "Natal",
    "Gramado",
    "Florianópolis",
    "Curitiba",
    "Porto Alegre",
    "Brasília",
    "Cuiabá",
    "João Pessoa",
    "Maceió",
    "Santos",
    "Vitória",
    "Goiânia",
    "Aracaju",
    "Belém",
    "Cabo Frio",
  ];

  return (
    <div>
      <h2>A liberdade de explorar está a um volante de distância</h2>
      <p>Encontramos as melhores ofertas em aluguel de carros</p>

      <ul>
        {cidades.map((cidade, index) => (
          <li key={index}>
            <a href="https://www.passabrasil.com.br" target="_blank" rel="noopener noreferrer">
              Alugar carro em {cidade}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Carros;
