import React, { useState } from 'react';
import './App.css';

function Newsletter() {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Adicione sua lógica de envio de formulário aqui
    // Por enquanto, apenas exibiremos uma mensagem de sucesso no console
    console.log(`Email enviado para newsletter: ${email}`);
    // Limpar o campo de email após o envio
    setEmail('');
  };

  return (
    <div className='form'>
      <h3>Assine nossa newsletter para receber ofertas exclusivas</h3>
      <form onSubmit={handleSubmit}>
      <input
          type="nome"
          id="nome"
          name="nome"
          placeholder="Digite seu nome"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
          required
        />
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Digite seu e-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button className="button-assinar" type="submit">Assinar</button>
      </form>
    </div>
  );
}

export default Newsletter;
