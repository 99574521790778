import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
  return (
    <header>
      <div className="logo-container">
        <Link to="/">
          <img src="Passabg.png" alt="Logo" className="logo" />
        </Link>
      </div>
      <nav>
        <ul className="menu">
          <li><Link to="/passagens-aereas">PASSAGENS</Link></li>
          <li><Link to="/pacotes">PACOTES</Link></li>
          <li><Link to="/hospedagem">HOSPEDAGEM</Link></li>
          <li><Link to="/carros">CARROS</Link></li>
          <li><Link to="/passeios">PASSEIOS</Link></li>
          <li><Link to="/contato">CONTATO</Link></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
