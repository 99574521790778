import React, { useState } from 'react';

function Passeios() {
  const destinos = [
    { title: 'Beach Park', description: '', backgroundImage: 'beachpark.jpg', text:  'Fortaleza', link: 'https://beachpark.com.br/pt/'  },
    { title: 'Beto Carrero World', description: '', backgroundImage: 'betocarreiro.jpg', text:  'Santa Catarina', link: 'https://www.betocarrero.com.br/' },
    { title: 'Hopi Hari', description: '', backgroundImage: 'hopihari.jpg', text:  'Vinhedo', link: 'https://www.hopihari.com.br/' },
    { title: 'Parque Nacional do Iguaçu', description: '', backgroundImage: 'parquefoz.jpeg', text:  'Foz do Iguaçu', link: 'https://cataratasdoiguacu.com.br/' },
    { title: 'Cristo Redentor', description: '', backgroundImage: 'cristoredentor.jpeg', text:  'Rio de Janeiro', link: 'cristo-redentor' },
    { title: 'Museu de Arte de São Paulo (MASP)', description: '', backgroundImage: 'masp.jpg', text:  'São Paulo', link: 'masp' },
    { title: 'Pelourinho', description: '', backgroundImage:'pelourinho.jpg', text: 'Salvador', link: 'pelourinho' },
    { title: 'Pão de Açúcar', description: '', backgroundImage: 'paodeacucar.jpg', text:  'Rio de Janeiro', link: 'pao-de-acucar' },
  ];

  const [showAll, setShowAll] = useState(false);
  const [destinosToShow, setDestinosToShow] = useState(8);

  const handleShowAll = () => {
    setShowAll(true);
    setDestinosToShow(destinos.length);
  };

  return (
    <div>
      <h2>Passeios incríveis espalhados pelo Brasil:</h2>
      <div className="card-container">
        {destinos.slice(0, destinosToShow).map((destino, index) => (
          <a key={index} href={destino.link} target="_blank" rel="noopener noreferrer" className="card" style={{ backgroundImage: `url(${destino.backgroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center', width: '23%', position: 'relative' }}>
            <h3 style={{ position: 'absolute', top: '0px', left: '10px', color: '#fff', maxWidth: '90%', textAlign: 'left' }}>{destino.title}</h3>
            <p className="card-text" style={{ maxWidth: '90%', position: 'absolute', bottom: '0 px', left: '0px', color: '#000', textAlign: 'right' }}>{destino.text}</p>
          </a>
        ))}
      </div>
      {!showAll && (
        <button onClick={handleShowAll} style={{ display: 'block', margin: '0 auto' }}>
          Carregar Todos
        </button>
      )}
    </div>
  );
}

export default Passeios;
