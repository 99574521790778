function PassagensAereas() {
  const destinos = [
    { title: 'Onibus', description: 'Descrição do Oninus', backgroundImage: 'onibus.jpg', text: 'A partir de R$ 64,90', link: 'destino-onibus' },
    { title: 'Avião', description: 'Descrição do Avião', backgroundImage: 'aviao.jpg', text: 'A partir de R$ 99,90', link: 'destino-aviao' },
    { title: 'Cruzeiro', description: 'Descrição do Cruzeiro', backgroundImage: 'cruzeiro.jpg', text: 'A partir de R$ 279,90', link: 'destino-cruzeiro' },
    { title: 'Trem', description: 'Descrição do Trem', backgroundImage: 'trem.jpg', text: 'A partir de R$ 34,90', link: 'destino-trem' },
  ];

  return (
    <div>
      <h2>Escolha o seu meio de transporte:</h2>
      <div className="card-container">
        {destinos.map((destino, index) => (
          <a key={index} href={destino.link} className="card" style={{ backgroundImage: `url(${destino.backgroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center', width: '23%', position: 'relative' }}>
            <h3 style={{ position: 'absolute', top: '0px', left: '10px', color: '#fff', maxWidth: '90%', textAlign: 'left' }}>{destino.title}</h3>
            <p className="card-text" style={{ maxWidth: '90%', position: 'absolute', bottom: '0 px', left: '0px', color: '#000', textAlign: 'right' }}>{destino.text}</p>
          </a>
        ))}
      </div>
    </div>
  );
}

export default PassagensAereas;
